.react-multiple-carousel__arrow--right {
    right: 0;
}
.react-multiple-carousel__arrow--left {
    left: 0;
}

.react-multiple-carousel__arrow {
    background: rgba(0, 0, 0, 0);
    min-width: 0px;
}

.react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0);
}

.react-multiple-carousel__arrow:before {
    color: black;
}

.react-multiple-carousel__arrow:focus {
    outline: 0;
}
