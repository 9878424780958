body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

 :root {
    --secondary-background: #bababa;
    --primary-background: #f3f3f3;
    --key-color: #085735;
}

div {
    background-color: var(--primary-background);
}

a {
    color: var(--key-color);
}

a * {
    color: var(--key-color);
}

a:hover {
    color: var(--key-color);
    text-decoration: none;
}

.myNav a {
    color: var(--key-color);
}

.myNav a:hover {
    color: var(--key-color);
    text-decoration: underline;
}

.eventTabPicker a.active {
    color: var(--primary-background);
    background-color: var(--key-color);
    border-radius: 10px;
    font-weight: bolder;
}

.eventTabPicker a {
    color: var(--key-color);
    border: solid 1px var(--secondary-background);   
    border-radius: 10px;
}

.eventTabPicker a:hover {
    color: var(--secondary-background);
    background-color: var(--key-color);
    border: solid 1px var(--secondary-background);   
    border-radius: 10px;
}

a.active {
    color: var(--primary-background);
    background-color: var(--key-color);
    font-weight: bold;
}

.dropdown button {
    color: var(--primary-background);
    background-color: var(--key-color);
    border-color: var(--primary-background);
}

.dropdown button:hover {
    color: var(--primary-background);
}

th {
    font-weight: bolder;
}

.dropdown-menu {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

.matchContainer {
    background-color: var(--secondary-background);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border: solid 1px var(--secondary-background);
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

.matchContainer div {
    background-color: var(--secondary-background);
}

.matchElement {
    border-bottom: 1px solid lightslategrey;
}

.matchElement:last-child {
    border-bottom: 0px solid lightslategrey;
}

.sponsorBanner {
    padding-top: 10px;
    margin-bottom: 10px;

    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 10px;    
}

.sponsorBanner div  {
    background-color: white;
}

.sponsorBanner .carousel-indicators li {
    margin-top: 20px;
    color: gray;
    background-color: #007B85;
} 

.matchContainer .list-group-item {
    background-color: var(--primary-background);
}

.matchContainer .list-group-item div {
    background-color: var(--primary-background);
}

.matchContainer .list-group-item.active {
    background-color: var(--key-color);
    border-color: var(--key-color);
    color: var(--secondary-background)
 }

.myListView .list-group-item {
    background-color: var(--primary-background);
}

.myListView .list-group-item.active {
    background-color: var(--key-color);
    border-color: var(--secondary-background);
    color: var(--secondary-background);
    font-weight: bold;
 }

 .myListView * :hover * {
    color: var(--key-color);
 }

 .myListView :hover {
    color: var(--key-color);
 }

 .matchContainer .list-group {
    border-radius: 10px;  
 }

 a:hover .disclosureIndicator {
    text-decoration: underline;
    color: var(--key-color);
 }

 .card {
    background-color: var(--secondary-background);
 }

 .card-header div {
    background-color: var(--secondary-background);
 }

 .card .list-group-item {
    background-color: var(--primary-background);
 }

 .pageHeader a span {
    color: var(--key-color);
 }

.upcomingMatch {
    padding-left: 20px;
    padding-right: 20px;
    min-height: 50px;
}

.upcomingMatchContainer li {
    border: solid 1px rgba(0,0,0,0.1);      
}

.upcomingMatch a:hover * {
    color: var(--key-color);
    text-decoration: none;
 }

 .upcomingMatch a * {
    color: white;  
 }

 button div {
    background-color: inherit;
 }

 .statEntry .btn-outline-primary {
    color: white;  
    border-color: white;
 }

 .matchDetailPlayerSummary {
    background-color: var(--primary-background);
 }

 .dateRangePicker div {
    background-color: inherit;
 }

.eventMetadata .card-footer {
    background-color: var(--primary-background);
}
