.display-enter {
    opacity: 0;
    transform: scale(0.8);
}
.display-enter-active {
    opacity: 1;
    transform: scale(1);
    /* transition timing needs to be within the CSSTransition timeout otherwise the transition will still be completing when the element is removed from the DOM */
    transition: all 300ms linear;
}
.display-exit {
    opacity: 1;
}
.display-exit-active {
    opacity: 0;
    transform: scale(0.8);
    /* see timeout comments above */
    transition: all 300ms linear;
}

.display2-enter {
    opacity: 0;
}
.display2-enter-active {
    opacity: 1;
    /* transition timing needs to be within the CSSTransition timeout otherwise the transition will still be completing when the element is removed from the DOM */
    transition: all 300ms linear;
}
.display2-exit {
    opacity: 1;
}
.display2-exit-active {
    opacity: 0;
    /* see timeout comments above */
    transition: all 300ms linear;
}
